import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';

const Header = ({ htmlStr, scriptsStr }) => {
  // htmlStr が空の間はなにもせずに終了
  if (!htmlStr) {
    return null;
  }

  // noscript 以外のHTML文字列をHTML化して抽出
  // TODO_201910: 現在、Navi API から送られてくる script は3つ存在します。
  //              そのうち、NAVIAPI_SENTRY_SDK を取得する script がうまく読み込めていません。
  //              ただ、下記コンフルを見ると、本 Sentry はナビが正常に動作しているか監視するためのものであり、
  //              読み込めなくても特に問題がないようです。したがって、今後解決していきます。
  //              https://confl.arms.dmm.com/pages/viewpage.action?pageId=415921794
  const parsedScripts = parse(scriptsStr).filter(e => e.type !== 'noscript');

  return (
    <>
      <Helmet>
        {scriptsStr && parsedScripts}
      </Helmet>
      {
        parse(htmlStr)
      }
    </>
  );
};

Header.propTypes = {
  htmlStr: PropTypes.string.isRequired,
  scriptsStr: PropTypes.string.isRequired,
};

export default Header;
