import React from 'react';
import PropTypes from 'prop-types';
import ReactModal from 'react-modal';

const ReviewCommonModal = ({
  openModal,
  setOpenModal,
  isUpdate,
}) => {
  // screenreadersにモーダルが表示されてることを伝える
  // 参考：http://reactcommunity.org/react-modal/accessibility/
  ReactModal.setAppElement('#root');

  return (
    <ReactModal
      isOpen={openModal}
      className="pfReview__create--modal"
      overlayClassName="pfReview__create--modalOverlay"
      onRequestClose={setOpenModal}
    >
      {
        isUpdate
          ? <h2>レビューの編集に失敗しました</h2>
          : <h2>レビューの投稿に失敗しました</h2>
      }
      <div className="pfReview__modal-text">
        <p>
          現在アクセスが集中しております。
          <br />
          時間をおいて再度アクセスしてください。
        </p>
      </div>
      <button type="button" className="pfReview__modal-btn--primary" onClick={() => setOpenModal(false)}>閉じる</button>
    </ReactModal>
  );
};


ReviewCommonModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  setOpenModal: PropTypes.func.isRequired,
  isUpdate: PropTypes.bool.isRequired,
};

export default ReviewCommonModal;
