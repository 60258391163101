import React from 'react';
import PropTypes from 'prop-types';

import ReviewUpdateGoodsInfo from './ReviewCommonGoodsInfo';
import ReviewUpdateForm from './ReviewCommonForm';
import ReviewUpdateNotes from './ReviewCommonNotes';
import ReviewCommonCampaign from './ReviewCommonCampaign';
import ReviewCommonParcelButton from './ReviewCommonParcelButton';

// レビュー編集画面で表示するメインコンテンツ
const ReviewUpdate = ({
  materials,
  requestLoginCheckAPI,
  requestUpdateReviewAPI,
  purchaseListMaterials,
}) => {
  // materials が未取得の場合はなにもせずに終了
  if (!materials) {
    return null;
  }

  return (
    <div id="w">
      <div id="review-post" className="pfReview__page--write pfReview">
        <div className="pfReview__container">
          <div className="pfReview__headline">
            <h1>レビューの編集</h1>
          </div>

          <ReviewUpdateGoodsInfo goods={materials.content} />
          <ReviewUpdateForm
            materials={materials}
            requestLoginCheckAPI={requestLoginCheckAPI}
            requestSubmitReviewAPI={requestUpdateReviewAPI}
            purchaseListMaterials={purchaseListMaterials}
          />
          <ReviewUpdateNotes />
          <div className="pfReview__writePageParcelButton">
            <ReviewCommonParcelButton reviewerID={materials.reviewer.id} />
          </div>
          <div className="pfReview__writePageCampaign">
            <ReviewCommonCampaign />
          </div>
        </div>
      </div>
    </div>
  );
};

ReviewUpdate.propTypes = {
  materials: PropTypes.shape({
    review: PropTypes.object.isRequired,
    reviewer: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
  }).isRequired,
  requestLoginCheckAPI: PropTypes.func.isRequired,
  requestUpdateReviewAPI: PropTypes.func.isRequired,
};

export default ReviewUpdate;
