import React from 'react';
import PropTypes from 'prop-types';

// 星一個のコンポーネント
const ReviewCreateFormStarButton = ({
  id, clickedStarID, setClickedStarID, mouseOveredStarID,
  setMouseOveredStarID, isMouseOveredOnList, setIsMouseOveredOnList,
}) => {
  // 黄色表示はマウスアウトされている時のみ
  const isMouseOut = !isMouseOveredOnList || mouseOveredStarID === 0;
  const isYellow = (id <= clickedStarID) && isMouseOut;
  const isBlue = (id <= mouseOveredStarID);

  return (
    <>
      {/* eslint-disable jsx-a11y/mouse-events-have-key-events */}
      <span
        data-star={id}
        onClick={() => {
          setClickedStarID(id);
          setMouseOveredStarID(0);
        }}
        onMouseOver={() => {
          setMouseOveredStarID(id);
          setIsMouseOveredOnList(true);
        }}
        onMouseOut={() => {
          setMouseOveredStarID(0);
          setIsMouseOveredOnList(false);
        }}
        className={[
          'pfReview__form--star-label',
          isYellow ? 'is-checked' : '',
          isBlue ? 'is-hover' : '',
        ].join(' ')}
        role="presentation"
      >
        {`${id}つ星 (5つ星中)`}
      </span>
    </>
  );
};

ReviewCreateFormStarButton.propTypes = {
  id: PropTypes.number.isRequired,
  clickedStarID: PropTypes.number.isRequired,
  setClickedStarID: PropTypes.func.isRequired,
  mouseOveredStarID: PropTypes.number.isRequired,
  setMouseOveredStarID: PropTypes.func.isRequired,
  isMouseOveredOnList: PropTypes.bool.isRequired,
  setIsMouseOveredOnList: PropTypes.func.isRequired,
};

export default ReviewCreateFormStarButton;
