import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/stable';

import React from 'react';
import { isIE } from 'react-device-detect';

import ReactDOM from 'react-dom';
import App from './App';

if (isIE) {
  alert('Internet Explorer（IE）は非推奨です。\n Microsoft Edge等のブラウザをご利用ください。');
  window.history.back();
} else {
  ReactDOM.render(<App />, document.getElementById('root'));
}
