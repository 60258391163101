import React from 'react';
import PropTypes from 'prop-types';

// エラーページ用の静的なフッター（NaviAPIから取得したフッターではない）
const ErrorFooter = ({ isComPage }) => {
  const domain = isComPage ? 'com' : 'co.jp';

  return (
    <footer className="_n4v1-footer">
      <div className="_n4v1-mainfooter">
        <span>
          <a href={`https://terms.dmm.${domain}/profile/`} target="_self">
            会社概要
          </a>
        </span>
        {
          isComPage
            ? (
              <span>
                <a
                  href="https://dmm-corp.com/press/"
                  /* eslint-disable-next-line react/jsx-no-target-blank */
                  target="_blank"
                  rel="nofollow noopener"
                >
                  プレスリリース
                </a>
              </span>
            )
            : null
        }
        <span>
          <a
            href={`https://terms.dmm.${domain}/member/`}
            target="_self"
            rel="nofollow"
          >
            会員規約
          </a>
        </span>
        <span>
          <a
            href={`https://terms.dmm.${domain}/privacy/`}
            target="_self"
            rel="nofollow"
          >
            個人情報保護に関して
          </a>
        </span>
        <span>
          <a
            href={`https://terms.dmm.${domain}/commerce/`}
            target="_self"
            rel="nofollow"
          >
            特定商取引法に基づく表示
          </a>
        </span>
        {
          isComPage
            ? (
              <>
                <span>
                  <a
                    href="https://dmm-corp.com/business/partnership/"
                    /* eslint-disable-next-line react/jsx-no-target-blank */
                    target="_blank"
                    rel="noopener"
                  >
                    特別採用・事業譲渡(M&amp;A)に関して
                  </a>
                </span>
                <span>
                  <a
                    href="https://dmm-corp.com/recruit/"
                    /* eslint-disable-next-line react/jsx-no-target-blank */
                    target="_blank"
                    rel="noopener"
                  >
                    スタッフ募集
                  </a>
                </span>
              </>
            )
            : null
        }
        <span><a href={`https://terms.dmm.${domain}/partner/`} target="_self">ビジネスパートナー募集</a></span>
      </div>
      <div className="_n4v1-copyright">Copyright © since 1998 DMM All Rights Reserved.</div>
    </footer>
  );
};

ErrorFooter.propTypes = {
  isComPage: PropTypes.bool.isRequired,
};

export default ErrorFooter;
