import * as common from '../common';
import { embedTrackingtag, requestI3, requestGTMGA } from '../../utils/trackingtag';

// get /trackingtag
// 指定したデバイス, url, ログイン状態, サービス(com, r18), オプションからトラッキングタグを取得するエンドポイント
export default async (deviceType, requestUrl, isLogin, serviceType, subTitle, tagOptions = {}) => {
  const res = await common.post('/trackingtag', {
    device: deviceType,
    url: requestUrl,
    login: isLogin,
    service: serviceType,
    options: tagOptions,
  }, true);

  // トラッキングタグが未設置(初回アクセス時)の場合
  if (document.getElementById('tracking_area') == null) {
    // トラッキングタグを設置
    embedTrackingtag(res.tags);
  } else {
    // タグが既に設置されている(初回アクセス以降の)場合
    // i3へリクエスト
    requestI3(res.tags);
  }

  // gtm/gaへのリクエスト
  requestGTMGA(subTitle);
};
