import React from 'react';

import '../../../../styles/common/maintenance/pc/MaintenancePage.css';
import { IsAdultPage } from '../../../../utils/page';

const PageMaintenance = () => (
  <div id="w">
    <div className="mg-auto pd-t12 d-rst pc">
      <div className="d-area page-maintenance_pc">
        <div className="d-capt">
          <h2 className="d-headline">
          メンテナンスのお知らせ
          </h2>
        </div>
        <div className="sect">
          <div className="item">
            <p>
              いつもレビューをご記載いただきましてありがとうございます。
              <br />
              下記日時にてメンテナンスを行いますので、ユーザーレビューのご記載及び編集ができなくなりますので、何卒ご了承願います。
            </p>
            <p className="date">
              2021年●月●日00時00分〜2021年●月●日00時00分
              <span className="caution">終了時間は多少前後する場合がございます。</span>
            </p>
          </div>
          {
            IsAdultPage()
              ? <p className="top-link"><a href="https://www.dmm.co.jp/">FANZAトップへ</a></p>
              : <p className="top-link"><a href="https://www.dmm.com/">DMMトップへ</a></p>
          }
        </div>
      </div>
    </div>
  </div>

);

export default PageMaintenance;
