import * as common from '../common';

// get /naviapi
// 指定したデバイス, サービス(com, r18)，ヘッダー，フッターのナビを取得するエンドポイント
export default async (deviceType, serviceType, headerType, footerType) => {
  const res = await common.get('/naviapi', {
    device: deviceType, service: serviceType, headers: headerType, footers: footerType,
  });
  return res;
};
