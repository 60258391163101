import React from 'react';

import './styles/common/App.css';
import './styles/common/review.css'; // レビューページ共通スタイルを読込
import Router from './router';

const App = () => (
  <div id="App">
    <Router />
  </div>
);

export default App;
