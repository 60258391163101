import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import Layout from '../../layouts';
import ReviewCreateCompleteMessage from '../../components/review/ReviewCreateCompleteMessage';
import { GetReviewerReq } from '../../api/reviewer';
import {
  DEFAULT_REVIEWER_ID,
} from '../../constants';


// レビュー投稿完了画面
const Complete = ({ history, location }) => {
  const [reviewerID, setReviewerID] = useState(DEFAULT_REVIEWER_ID);
  const materials = location.state.materials ? location.state.materials : '';

  useEffect(() => {
    // NOTE_201910:
    // ニックネーム新規登録後のレビュー投稿では、投稿画面関連のコンポーネント内にはレビュアーIDを持っていない。
    // （DMMAPI の CreateReview API がレスポンスで返さないため）
    // したがって、DMMAPI からのリプレイスを実施するまでは、API 経由でレビュアーIDを取得する。
    // DMMAPI から脱却完了後は投稿/編集完了画面で取得した materials からデータを取得する。
    const requestGetReviwersAPI = async () => {
      const res = await GetReviewerReq();

      // データの取得に失敗した場合はサーバエラーページにリダイレクト
      // TODO_201910: /api/common.js の処理としてサーバエラーページにリダイレクトさせる
      if (res.error) {
        history.push('/server-error');
        return null;
      }

      // 投稿完了画面表示に必要な情報（ニックネーム）を取得できなかった場合は NotFound 画面にリダイレクト
      if (res.reviewer.nickname === '') {
        history.push('/not-found');
        return null;
      }

      setReviewerID(res.reviewer.id);
      return null;
    };


    requestGetReviwersAPI();
  });

  // 前画面からデータ（contentURL）が渡されていない場合は NotFound 画面へリダイレクト
  if (!location.state) {
    history.push('/not-found');
    return null;
  }

  return (
    <Layout
      subTitle="レビュー投稿完了"
    >
      <ReviewCreateCompleteMessage
        materials={materials}
        contentURL={location.state.contentURL}
        floor={location.state.floor}
        reviewerID={reviewerID}
      />
    </Layout>
  );
};

Complete.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    state: PropTypes.shape({
      contentURL: PropTypes.string.isRequired,
      // reviewerID: PropTypes.string.isRequired, // TODO_201910: DMMAPI 脱却完了後は前画面から渡されるレビュアーIDを使用する
    }).isRequired,
  }).isRequired,
};

export default withRouter(Complete);
