import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import PrivateRouter from './PrivateRouter';
import ReviewCreateComplete from '../pages/review/Complete';
import ReviewCreate from '../pages/review/Create';
import ReviewUpdate from '../pages/review/Update';
import ReviewIsConfirming from '../pages/review/IsConfirming';
import ContentIsPreparing from '../pages/review/IsPreparing';
import ServerError from '../pages/common/ServerError';
import NotFound from '../pages/common/NotFound';
import Maintenance from '../pages/common/maintenance';
import ReviewBan from '../pages/review/IsBan';
import Exists from '../pages/review/Exists';
import { MAINTENANCE_FLAG } from '../constants';


function RouterSwitch() {
  if (MAINTENANCE_FLAG) {
    // メンテナンス期間の場合、全ページメンテナンスページを表示
    return (
      <Router>
        <Switch>
          <Route component={Maintenance} />
        </Switch>
      </Router>
    );
  }
  return (
    <Router>
      <Switch>
        {/* TODO_201910: エラーページや投稿完了、承認待ち画面について、URL 直叩きでアクセスできてしまっていいのか検討 */}

        {/* レビュー投稿 */}
        <PrivateRouter exact path="/create" component={ReviewCreate} />
        <PrivateRouter exact path="/create/complete" component={ReviewCreateComplete} />
        <PrivateRouter exact path="/create/confirming" component={ReviewIsConfirming} />
        <PrivateRouter exact path="/create/exists" component={Exists} />

        {/* レビュー編集 */}
        <PrivateRouter exact path="/update" component={ReviewUpdate} />
        <PrivateRouter exact path="/update/complete" component={ReviewCreateComplete} />
        <PrivateRouter exact path="/update/confirming" component={ReviewIsConfirming} />

        {/* レビュー受付開始前ページ */}
        <PrivateRouter exact path="/content/preparing" component={ContentIsPreparing} />

        {/* メンテナンスページ */}
        <Route exact path="/maintenance" component={Maintenance} />

        {/* エラーページ */}
        <Route exact path="/server-error" component={ServerError} />
        <Route exact path="/ban" component={ReviewBan} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}
export default RouterSwitch;
