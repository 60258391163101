import React from 'react';

import '../../../../styles/common/errors/pc/ErrorPage.css';
import { IsAdultPage } from '../../../../utils/page';

const PageNotFound = () => (
  <div id="w">
    <div className="w90 mg-auto pd-t12 d-rst">
      <div className="d-area">
        <div className="d-capt">
          <h2 className="d-headline">
          指定されたページが見つかりません
            <span className="d-txten">404 Not Found</span>
          </h2>
        </div>
        <div className="sect">
          <p>
            お客様がアクセスしようとしたページは見つかりませんでした。
            <br />
            お探しのページは、移動または削除された可能性があります。
            <br />
            申し訳ありませんが、以下のリンクからご覧になりたいページをお探しください。
          </p>
          {
            IsAdultPage()
              ? <p><a href="https://www.dmm.co.jp/" className="arrow">FANZAトップへ</a></p>
              : <p><a href="https://www.dmm.com/" className="arrow">DMMトップへ</a></p>
          }
        </div>
      </div>
    </div>
  </div>

);

export default PageNotFound;
