import React from 'react';

import { IsAdultPage } from '../../utils/page';
import { requestEventI3Complete, requestEventI3Create } from '../../utils/trackingtag';

// レビュー利用規約に関するメッセージのコンポーネント
const ReviewTermsLink = () => {
  // ドメイン別のDMM規約ページのURLを取得
  const DMM_TERMS_URL = IsAdultPage()
    ? `${process.env.REACT_APP_ADULT_DMM_TERMS_URL}`
    : `${process.env.REACT_APP_COM_DMM_TERMS_URL}`;
  const analytics = (elem) => {
    if (window.location.href.match(/complete/)) {
      requestEventI3Complete(elem);
    } else if (window.location.href.match(/create/)) {
      requestEventI3Create(elem);
    }
  };

  return (
    <a
      href={`${DMM_TERMS_URL}/review_service/`}
      onClick={() => analytics('termLink')}
    >
      レビュー利用規約
    </a>
  );
};

export default ReviewTermsLink;
