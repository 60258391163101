import React from 'react';

import Layout from '../../layouts';
import PcPageNotFound from '../../components/common/errors/pc/NotFound';
import SpPageNotFound from '../../components/common/errors/sp/NotFound';
import { GetDeviceType } from '../../utils/page';
import { DEVICE_TYPE_SP } from '../../constants';

let PageNotFound = PcPageNotFound;
let subTitle = '指定されたページが見つかりません';

// アクセス元デバイスがspの場合表示するコンポーネントとタイトルを変更
if (GetDeviceType() === DEVICE_TYPE_SP) {
  PageNotFound = SpPageNotFound;
  subTitle = 'Not Found';
}

// 404ページ
const NotFound = () => (
  <Layout
    subTitle={subTitle}
  >
    <PageNotFound />
  </Layout>
);


export default NotFound;
