import * as common from '../common';

// get /reviewers
export const GetReviewerReq = async () => {
  const res = await common.get('/reviewers', {}, true);
  return res;
};

// get /reviewers/nickname/check
export const CheckNicknameAvailableReq = async (nickname) => {
  const res = await common.get('/reviewers/nickname/check', { nickname }, false);
  return res;
};
