import React from 'react';

import '../../../../styles/common/errors/sp/ErrorPage.css';
import { IsAdultPage } from '../../../../utils/page';

const PageNotFound = () => (
  <div id="dm-page02">
    <div id="dm-content02">
      <div id="dm-content02-inn">
        <div className="main">

          <section id="error" className="sect pd-10">
            <h1>指定されたページが見つかりません</h1>
            <p className="pd-t10">
              お客様がアクセスししようとしたページが見つかりませんでした。
              <br />
              お探しのページは、移動または削除された可能性があります。
            </p>
          </section>

          <div className="bx-back">
            {
              IsAdultPage()
                ? <a href="https://www.dmm.co.jp/" className="dp-ib"><div className="btn-rct-240s btn-color-gra">FANZAトップへ</div></a>
                : <a href="https://www.dmm.com/" className="dp-ib"><div className="btn-rct-240s btn-color-gra">DMMトップへ</div></a>
            }
          </div>

        </div>
      </div>
    </div>
  </div>
);

export default PageNotFound;
