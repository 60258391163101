import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';
import { IsAdultPage } from '../../utils/page';

// title や meta 情報を扱うコンポーネント
const CustomHead = ({ headStr, subTitle }) => {
  // headStrが空の間はなにもせずに終了
  if (!headStr) {
    return null;
  }

  // noscript 以外のHTML文字列をHTML化して抽出
  // -> NOTE: JS が使えないと API リクエストできないため、noscript タグの内容を取得できない
  //    一旦 noscript タグは無視
  const parsedHtml = parse(headStr).filter(e => e.type !== 'noscript');
  const isAdult = IsAdultPage();

  return (
    <>
      <Helmet>
        <link rel="shortcut icon" href={isAdult ? 'favicon-cojp.ico' : 'favicon-com.ico'} />
        <title>
          {subTitle}
          {isAdult ? ' - FANZA' : ' - DMM.com'}
        </title>
        {parsedHtml}
      </Helmet>
    </>
  );
};

CustomHead.propTypes = {
  subTitle: PropTypes.string.isRequired,
  headStr: PropTypes.string.isRequired,
};

export default CustomHead;
