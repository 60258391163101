import * as common from '../common';

// get /reviews/materials
export const GetMaterialsReq = async (contentID, shopName, serviceName) => {
  const res = await common.get('/reviews/materials', {
    content_id: contentID,
    shop_name: shopName,
    service_name: serviceName,
  }, true);
  return res;
};

// post /review
// ニックネーム, レビュー登録内容, デバイス,ブラウザ
//  -> ニックネーム情報はニックネーム登録時のみ必須
export const CreateReview = async (nickname, review, device, browser) => {
  const res = await common.post('/reviews', {
    nickname,
    review,
    device,
    browser,
  }, true);
  return res;
};

// get (購入済商品情報の取得 最大6件)
export const GetPurchaseList = async (contentID, attribute) => {
  const res = await common.get('/contents/purchased', {
    content_id: contentID,
    attribute,
  }, true);
  return res;
};
