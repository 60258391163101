import React from 'react';
import PropTypes from 'prop-types';

import ReviewCreateGoodsInfo from './ReviewCommonGoodsInfo';
import ReviewCreateForm from './ReviewCommonForm';
import ReviewCreateNotes from './ReviewCommonNotes';
import ReviewCommonCampaign from './ReviewCommonCampaign';
import ReviewCommonParcelButton from './ReviewCommonParcelButton';

// レビュー投稿画面で表示するメインコンテンツ
const ReviewCreate = ({
  materials,
  requestLoginCheckAPI,
  requestCreateReviewAPI,
  requestCheckNicknameAPI,
  purchaseListMaterials,
}) => {
  // materials が未取得の場合はなにもせずに終了
  if (!materials) {
    return null;
  }

  return (
    <div id="w">
      <div id="review-post" className="pfReview__page--write pfReview">
        <div className="pfReview__container">
          <div className="pfReview__headline">
            <h1>レビューの投稿</h1>
          </div>

          <ReviewCreateGoodsInfo
            goods={materials.content}
          />
          <ReviewCreateForm
            materials={materials}
            requestLoginCheckAPI={requestLoginCheckAPI}
            requestSubmitReviewAPI={requestCreateReviewAPI}
            requestCheckNicknameAPI={requestCheckNicknameAPI}
            purchaseListMaterials={purchaseListMaterials}
          />
          <ReviewCreateNotes />
          <div className="pfReview__writePageParcelButton">
            <ReviewCommonParcelButton
              reviewerID={materials.reviewer.id}
            />
          </div>
          <div className="pfReview__writePageCampaign">
            <ReviewCommonCampaign />
          </div>
        </div>
      </div>
    </div>
  );
};

ReviewCreate.propTypes = {
  materials: PropTypes.shape({
    reviewer: PropTypes.object.isRequired,
    content: PropTypes.object.isRequired,
  }).isRequired,
  requestLoginCheckAPI: PropTypes.func.isRequired,
  requestCreateReviewAPI: PropTypes.func.isRequired,
  requestCheckNicknameAPI: PropTypes.func.isRequired,
};

export default ReviewCreate;
