import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  REVIEW_CREATE_FORM_INPUT_PLACEHOLDER,
  REVIEW_CREATE_FORM_INPUT_MIN_LENGTH,
  REVIEW_CREATE_FORM_INPUT_MAX_LENGTH,
} from '../../constants/index';

// レビュー投稿フォームで使用する TextArea コンポーネント
const ReviewCreateFormTextArea = ({
  kind, rows, cols, setInputString, setErrorMsg, defaultValue,
}) => {
  // 文字数のバリデーションを行う関数
  //  - true : バリデーションエラーあり
  //  - false: バリデーションエラーなし
  const validateMinLength = str => str.length < REVIEW_CREATE_FORM_INPUT_MIN_LENGTH[kind];
  const validateMaxLength = str => REVIEW_CREATE_FORM_INPUT_MAX_LENGTH[kind] < str.length;

  // 入力値をバリデートする関数
  const validateInputString = (str) => {
    // eslint-disable-next-line no-irregular-whitespace
    const strReplace = str.replace(/( |　){2,}/g, ' ');

    // 未入力時はバリデートしない
    if (!strReplace) {
      // すでにエラーメッセージがセットされている可能性があるので空文字でリセット
      setErrorMsg('');
      return;
    }

    // バリデーションエラー時はエラーメッセージを state にセット
    if (validateMinLength(strReplace)) {
      setErrorMsg(`${REVIEW_CREATE_FORM_INPUT_MIN_LENGTH[kind]}文字以上入力してください。`);
    } else if (validateMaxLength(strReplace)) {
      setErrorMsg(`${REVIEW_CREATE_FORM_INPUT_MAX_LENGTH[kind]}文字以内で入力してください。`);
    } else {
      // すでにエラーメッセージがセットされている可能性があるので空文字でリセット
      setErrorMsg('');
    }
  };

  // 入力値の state をバリデート後に更新する関数
  const updateInputString = (str) => {
    // eslint-disable-next-line no-irregular-whitespace
    const strReplace = str.replace(/( |　){2,}/g, '');

    validateInputString(strReplace);
    setInputString(strReplace);
  };

  useEffect(() => {
    // デフォルト値のバリデーション
    if (defaultValue) {
      validateInputString(defaultValue);
    }

  // NOTE_201910: 下記 ESLint 無効化について、
  // validateInputString を依存関係に含める必要があるが、本質的に関係ないので無視
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label htmlFor={`review_${kind}`}>{kind}</label>
      <textarea
        className="fn-input pfReview__form--textarea"
        name={kind}
        id={`review_${kind}`}
        defaultValue={defaultValue}
        placeholder={REVIEW_CREATE_FORM_INPUT_PLACEHOLDER[kind]}
        rows={rows}
        cols={cols}
        onChange={e => updateInputString(e.target.value)}
      />
    </>
  );
};

ReviewCreateFormTextArea.defaultProps = {
  defaultValue: '',
};

ReviewCreateFormTextArea.propTypes = {
  kind: PropTypes.string.isRequired,
  rows: PropTypes.string.isRequired,
  cols: PropTypes.string.isRequired,
  setInputString: PropTypes.func.isRequired,
  setErrorMsg: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
};

export default ReviewCreateFormTextArea;
