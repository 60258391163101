import React from 'react';
import '../../../../styles/common/errors/sp/ErrorPage.css';
import { IsAdultPage } from '../../../../utils/page';

const ReviewBan = () => (
  <div id="dm-page02">
    <div id="dm-content02">
      <div id="dm-content02-inn">
        <div className="main">

          <section id="error" className="sect pd-10">
            <h1>レビューの投稿を禁止させていただいております</h1>
            <p className="pd-t10">
              お客様が今までに投稿した内容が利用規約に反していました。
              <br />
              ご了承ください。
            </p>
          </section>

          <div className="bx-back">
            {
              IsAdultPage()
                ? <a href="https://www.dmm.co.jp/" className="dp-ib"><div className="btn-rct-240s btn-color-gra">FANZAトップへ</div></a>
                : <a href="https://www.dmm.com/" className="dp-ib"><div className="btn-rct-240s btn-color-gra">DMMトップへ</div></a>
            }
          </div>

        </div>
      </div>
    </div>
  </div>
);

export default ReviewBan;
