import React from 'react';
import PropTypes from 'prop-types';
import parse from 'html-react-parser';

const Footer = ({ htmlStr }) => {
  // htmlStr が空の間はなにもせずに終了
  if (!htmlStr) {
    return null;
  }

  return (
    <>
      {
        parse(htmlStr)
      }
    </>
  );
};

Footer.propTypes = {
  htmlStr: PropTypes.string.isRequired,
};

export default Footer;
