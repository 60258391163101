import {
  DEVICE_TYPE_PC, DEVICE_TYPE_SP, ADULT_DOMAIN_REGEXP, SCREEN_MAX_WIDTH_SP,
} from '../constants';

// 端末タイプを取得する関数
//  -> 画面サイズから PC または SP を判定
// eslint-disable-next-line import/prefer-default-export
export const GetDeviceType = () => {
  if (window.parent.screen.width <= SCREEN_MAX_WIDTH_SP) {
    return DEVICE_TYPE_SP;
  }
  return DEVICE_TYPE_PC;
};

// アダルトページか否かを取得する関数
export const IsAdultPage = () => !!window.location.host.match(ADULT_DOMAIN_REGEXP);

// ドメインごとのログインページにリダイレクトさせる関数
export const RedirectToLoginPage = (encodedCurrentURL) => {
  let redirectURL;
  if (IsAdultPage()) {
    // アダルトのログインページ
    redirectURL = `${process.env.REACT_APP_ADULT_LOGIN_PAGE_URL}/=/path=${encodedCurrentURL}`;
  } else {
    // 非アダルトのログインページ
    redirectURL = `${process.env.REACT_APP_COM_LOGIN_PAGE_URL}/=/path=${encodedCurrentURL}`;
  }
  window.location.href = redirectURL;
};
