import React from 'react';
import PropTypes from 'prop-types';

import BackToMyReviewsPageButton from './ReviewBackToMyReviewsPageButton';
import BackToGoodsPageButton from './ReviewBackToGoodsPageButton';
import { NO_REVIEWER_ID } from '../../constants';

// レビュー投稿済み画面で表示するメインコンテンツ
const ReviewExists = ({ reviewerID, contentURL }) => (
  <div id="w">
    <div id="review-post" className="pfReview__page--complete pfReview__page--write pfReview">
      <div className="pfReview--inner">

        <h1 className="pfReview__title">この商品のレビューは投稿済みです</h1>
        <p className="pfReview__lead">
          商品によっては、複数商品やシリーズを通して1つのレビューとなる場合があります。
        </p>

        <div className="pfReview__btn">
          {
            reviewerID !== NO_REVIEWER_ID
              ? (
                <BackToMyReviewsPageButton
                  reviewerID={reviewerID}
                />
              )
              : null
          }
          {
            contentURL
              ? (
                <BackToGoodsPageButton
                  contentURL={contentURL}
                />
              ) : null
          }
        </div>

      </div>
    </div>
  </div>
);

ReviewExists.propTypes = {
  reviewerID: PropTypes.number.isRequired,
  contentURL: PropTypes.string.isRequired,
};

export default ReviewExists;
