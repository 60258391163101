// eslint-disable-next-line import/prefer-default-export

// ホスト情報
export const HOST_COM = 'https://www.dmm.com'; // com
export const HOST_COJP = 'https://www.dmm.co.jp'; // co.jp

// 端末識別用の正規表現：SP用
//  -> 参考：https://stash.arms.dmm.com/projects/PFCOMMON/repos/dmmcommonsdk2/browse/src/DMM-CommonSDK2/UserAgent.php#137
export const DEVICE_TYPE_SP_REGEXP = /(iPhone|iPad|iPod|Android.*Mobile)/i;

// アダルトページ判定用の正規表現
export const ADULT_DOMAIN_REGEXP = /co.jp/;

// 端末タイプ（PC）
export const DEVICE_TYPE_PC = 'pc';

// 端末タイプ（SP）
export const DEVICE_TYPE_SP = 'sp';

// ログイン中であることを示す変数（数字番, トラッキングタグAPIで使用）
export const IS_LOGIN_TRUE = 1;

// アダルトページであることを示す変数（トラッキングタグAPIで使用）
export const SERVICE_ADULT = 'r18';

// comページであることを示す変数（トラッキングタグAPIで使用）
export const SERVICE_COM = 'com';

// トラッキングタグAPIのリクエストパラメータoption
export const TRACKINGTAG_API_OPTION = { i3: '1', gtm: '1', aff: '0' };

// ナビAPIで使用するヘッダータイプとフッタータイプ
export const NAVI_DEFAULT_HEADER_TYPE = 'global,logo,notification,coupon';
export const NAVI_DEFAULT_FOOTER_TYPE = 'link_top,subfooter,mainfooter,copyright';

// レビュー投稿/編集フォームで使用する Input の入力必須 最低文字数
export const REVIEW_CREATE_FORM_INPUT_MIN_LENGTH = {
  nickname: 1, // ニックネーム用
  title: 1, // タイトル用
  text: 30, // コメント用
};

// レビュー投稿/編集フォームで使用する Input の入力可能 最大文字数
export const REVIEW_CREATE_FORM_INPUT_MAX_LENGTH = {
  nickname: 12, // ニックネーム用
  title: 32, // タイトル用
  // FEATURE_201911: DMM API脱却時に制限を緩和する（1000文字→1500文字）
  text: 1000, // コメント用
};

// レビュー投稿/編集フォームで使用する Input のプレースホルダーに格納する文言
export const REVIEW_CREATE_FORM_INPUT_PLACEHOLDER = {
  nickname: `ニックネーム（～${REVIEW_CREATE_FORM_INPUT_MAX_LENGTH.nickname}文字）`, // ニックネーム用
  title: `伝えたいポイントを教えてください（～${REVIEW_CREATE_FORM_INPUT_MAX_LENGTH.title}文字）`, // タイトル用
  text: `おすすめしたいこと / おすすめできないことはありますか？（${REVIEW_CREATE_FORM_INPUT_MIN_LENGTH.text}～${REVIEW_CREATE_FORM_INPUT_MAX_LENGTH.text}文字）`, // コメント用
};

// レビュー投稿時のブラウザを表すID(web)
export const BROWSER_TYPE_WEB = 'web';
// レビューステータス
export const REVIEW_STATUS_PUBLISHED = 'published';
export const REVIEW_STATUS_CONFIRMING = 'confirming';
export const REVIEW_STATUS_RELATION = 'relation';
export const REVIEW_STATUS_NONE = 'none';

// レビュアーステータス
export const REVIEWER_STATUS_BLACK = 'black';

// HTTPステータスコード
export const STATUS_BAD_REQUEST = 400;
export const STATUS_BAD_GATEWAY = 502;

// デバイスタイプをSPと判定するさいの最大画面横幅
export const SCREEN_MAX_WIDTH_SP = 430;

// レビュアー情報がない場合のレビュアーID
export const NO_REVIEWER_ID = 0;

// デフォルトのレビュアーID
export const DEFAULT_REVIEWER_ID = NO_REVIEWER_ID;

// メンテナンスの切り替え true:メンテナンス中
export const MAINTENANCE_FLAG = false;
