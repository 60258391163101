import React from 'react';

import '../../../../styles/common/maintenance/sp/MaintenancePage.css';
import { IsAdultPage } from '../../../../utils/page';

const PageMaintenance = () => (
  <div id="dm-page02">
    <div id="dm-content02">
      <div id="dm-content02-inn">
        <div className="main">
          <section className="sect pd-10 page-maintenance_sp">
            <h1 className="d-headline">メンテナンスのお知らせ</h1>
            <div className="pd-t10 item">
              <p>
                いつもレビューをご記載いただきましてありがとうございます。
                <br />
                下記日時にてメンテナンスを行いますので、ユーザーレビューのご記載及び編集ができなくなりますので、何卒ご了承願います。
              </p>
              <div className="term">
                <p className="date">
                  2021年●月●日00時00分〜
                  <span>2021年●月●日00時00分</span>
                  <span className="caution">終了時間は多少前後する場合がございます。</span>
                </p>
              </div>
            </div>
          </section>

          <div className="bx-back">
            {
              IsAdultPage()
                ? <a href="https://www.dmm.co.jp/" className="dp-ib"><div className="btn-rct-240s btn-color-gra">FANZAトップへ</div></a>
                : <a href="https://www.dmm.com/" className="dp-ib"><div className="btn-rct-240s btn-color-gra">DMMトップへ</div></a>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default PageMaintenance;
