import React from 'react';
import '../../../../styles/common/errors/pc/ErrorPage.css';
import { IsAdultPage } from '../../../../utils/page';

const ReviewBan = () => (
  <div id="w">
    <div className="w90 mg-auto pd-t12 d-rst">
      <div className="d-area">
        <div className="d-capt">
          <h2 className="d-headline">
          レビューの投稿を禁止させていただいております
          </h2>
        </div>
        <div className="sect">
          <p>
            お客様が今までに投稿した内容が利用規約に反していました。
            <br />
            ご了承ください。
          </p>
          {
            IsAdultPage()
              ? <p><a href="https://www.dmm.co.jp/" className="arrow">FANZAトップへ</a></p>
              : <p><a href="https://www.dmm.com/" className="arrow">DMMトップへ</a></p>
          }
        </div>
      </div>
    </div>
  </div>

);

export default ReviewBan;
