import React from 'react';
import { Helmet } from 'react-helmet';

import PcServerError from '../../components/common/errors/pc/ServerError';
import SpServerError from '../../components/common/errors/sp/ServerError';
import PcErrorFooter from '../../components/common/errors/pc/ErrorFooter';
import SpErrorFooter from '../../components/common/errors/sp/ErrorFooter';
import { GetDeviceType, IsAdultPage } from '../../utils/page';
import '../../styles/common/errors/pc/ErrorPage.css';
import '../../styles/common/errors/sp/ErrorPage.css';
import { DEVICE_TYPE_PC } from '../../constants';


// 500ページ
const ServerError = () => {
  const isPCPage = GetDeviceType() === DEVICE_TYPE_PC;
  const isComPage = !IsAdultPage();

  return (
    <>
      <Helmet>
        <link rel="shortcut icon" href={`${process.env.PUBLIC_URL}/${isComPage ? 'favicon-com.ico' : 'favicon-cojp.ico'}`} />

        <title>
          {
            isPCPage
              ? 'システムエラーが発生しました'
              : 'Internal Server Error'
          }
          {isComPage ? ' - DMM.com' : ' - FANZA'}
        </title>
      </Helmet>

      <div id="server-error">
        {/* Internal Server Error 時は、基本的に BFF が動作不能なので静的（固定）なヘッダーを表示 */}
        <header className="_n4v1-header">
          {
            isComPage
              ? (
                <a href="https://www.dmm.com/" className={`_n4v1-header-icons _n4v1-logo-${isPCPage ? 'pc' : 'sp'}`}>
                  <img src="https://p.dmm.com/p/pf/service/logotype/dmm_com.svg" alt="DMM.com" />
                </a>
              )
              : (
                <a
                  href="https://www.dmm.co.jp/top/?_ga=2.254544072.1149253027.1569981063-379754434.1568008904"
                  className={`_n4v1-header-icons _n4v1-logo-${isPCPage ? 'pc' : 'sp'}`}
                >
                  <img src="https://p.dmm.co.jp/p/pf/service/logotype/fanza_r18.svg" alt="FANZA(ファンザ)" />
                </a>
              )
          }
        </header>

        <div className="_n4v1-main-contents">
          {
            isPCPage
              ? (
                <div id="error-main-pc">
                  <PcServerError />
                </div>
              )
              : (
                <div id="error-main-sp">
                  <SpServerError />
                </div>
              )
          }
        </div>

        {/* Internal Server Error 時は、基本的に BFF が動作不能なので静的（固定）なフッターを表示 */}
        {
          isPCPage
            ? (
              <PcErrorFooter
                isComPage={isComPage}
              />
            )
            : (
              <SpErrorFooter
                isComPage={isComPage}
              />
            )
        }
      </div>
    </>
  );
};


export default ServerError;
