import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import '../styles/common/layouts.css';
import {
  DEVICE_TYPE_PC,
  SERVICE_ADULT,
  SERVICE_COM,
  IS_LOGIN_TRUE,
  TRACKINGTAG_API_OPTION,
  NAVI_DEFAULT_HEADER_TYPE,
  NAVI_DEFAULT_FOOTER_TYPE,
} from '../constants';
import { GetDeviceType, IsAdultPage } from '../utils/page';
import GetNavi from '../api/naviapi/naviapi';
import GetTrackingtag from '../api/trackingtag/index';
import CustomHead from '../components/common/CustomHead';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';

// 全ページ共通のレイアウトを決定するコンポーネント
// 親コンポーネントで定義するさいの <Layout> ここ </Layout> の部分が children に入る
const Index = ({ history, children, subTitle }) => {
  const [isLoading, setIsLoading] = useState(true); // 表示パーツが揃い次第 isLoading = false となる
  const [navi, setNavi] = useState(null);

  // 端末タイプ
  const deviceType = GetDeviceType();

  // サービスタイプ取得およびファビコン設定
  const isAdult = IsAdultPage();
  const serviceType = isAdult ? SERVICE_ADULT : SERVICE_COM;

  useEffect(() => {
    // ナビAPIから Head, Header, Footer 情報を取得する関数
    const requestGetNaviAPI = async () => {
      const res = await GetNavi(
        deviceType, serviceType, NAVI_DEFAULT_HEADER_TYPE, NAVI_DEFAULT_FOOTER_TYPE,
      );
      setNavi(res);
    };

    // トラッキングAPIからトラッキングタグを取得する関数
    const requestGetTrackingtagAPI = async () => {
      const requestURL = window.location.href;

      GetTrackingtag(
        deviceType, requestURL, IS_LOGIN_TRUE, serviceType, subTitle, TRACKINGTAG_API_OPTION,
      );
    };

    // 全ページ必須 API リクエストを一括送信する関数
    const requestMustAPIs = async () => {
      // 依存関係のない API リクエストは並列処理
      await Promise.all([
        requestGetNaviAPI(),
        requestGetTrackingtagAPI(),
      ]);

      setIsLoading(false);
    };

    requestMustAPIs();
  }, [deviceType, serviceType, subTitle]);

  // NOTE_201910: ローディング画面を作る必要が生じた場合は、下記リンクを参考
  //  https://reactjs.org/docs/react-api.html#reactlazy
  if (isLoading) {
    return (
      // ファビコンはAPIリクエストが完了していない状態でも表示
      // NOTE_201911: 表示に遅延が発生するため index.html で favicon-loading.ico をデフォルト表示
      <Helmet>
        <link rel="shortcut icon" href={isAdult ? 'favicon-cojp.ico' : 'favicon-com.ico'} />
      </Helmet>
    );
  }

  // navi 取得に失敗した場合はサーバエラー（500エラー）ページにリダイレクト
  // NOTE_201910: trackingtag は仕様的に無視して問題なし（https://confl.arms.dmm.com/pages/viewpage.action?pageId=72517719）
  if (navi.error) {
    history.push('/server-error');
    return null;
  }

  // ナビAPIから取得した Head, Header, Footer 情報を各コンポーネントに渡す
  return (
    <>
      <CustomHead
        headStr={navi.head}
        subTitle={subTitle}
      />
      <Header
        htmlStr={navi.header}
        scriptsStr={navi.scripts}
      />
      <div className="_n4v1-main-contents">
        <div id={GetDeviceType() === DEVICE_TYPE_PC ? 'review-main-pc' : 'review-main-sp'}>
          {children}
        </div>
      </div>
      <Footer
        htmlStr={navi.footer}
      />
    </>
  );
};

Index.defaultProps = {
  children: <span />,
};

Index.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  children: PropTypes.element,
  subTitle: PropTypes.string.isRequired,
};

export default withRouter(Index);
