import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { requestEventI3Complete, requestEventI3Create } from '../../utils/trackingtag';


// 商品詳細画面に戻るボタンのコンポーネント
const ReviewBackToGoodsPageButton = ({ contentURL }) => {
  const analytics = (elem) => {
    if (window.location.href.match(/complete/)) {
      requestEventI3Complete(elem);
    } else if (window.location.href.match(/create/)) {
      requestEventI3Create(elem);
    }
  };
  return (
    <p>
      <a
        href={contentURL}
        className="pfReview__btn--textlink"
        onClick={() => analytics('backContentLink')}
      >
      商品詳細ページに戻る
      </a>
    </p>
  );
};
ReviewBackToGoodsPageButton.propTypes = {
  contentURL: PropTypes.string.isRequired,
};

export default withRouter(ReviewBackToGoodsPageButton);
