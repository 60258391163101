import { IsAdultPage } from '../utils/page';
import { STATUS_BAD_REQUEST, STATUS_BAD_GATEWAY } from '../constants';

// APIエンドポイントのベースURL
const BASE_URL = IsAdultPage()
  ? `${process.env.REACT_APP_ADULT_BFF_URL}`
  : `${process.env.REACT_APP_COM_BFF_URL}`;

// fetch() 結果に応じてレスポンスを生成/変換
export const formatResponse = async (fetchPromise) => {
  // fetch() の Promise を解決
  //  -> fetch() はコネクション失敗で Exception を起こすため
  //     HTTPステータスによるエラーハンドリングとは別でハンドリングが必要（catch() を使用）
  const res = await fetchPromise.catch(e => ({
    status: STATUS_BAD_GATEWAY,
    error: e,
  }));

  if (res.error) {
    return res;
  }

  // HTTP ステータスコードが 400 以上の場合はエラーレスポンスを返却
  if (res.status >= STATUS_BAD_REQUEST) {
    return {
      status: res.status,
      error: await res.text(),
    };
  }

  return res.json();
};

// GETリクエスト
// ex) get(url='/logincheck', queries={
//       login_session_id: '1:169g8DjXcq5StKiPH',
//     }, sendCookie=true)
export const get = (url = '', queries = {}, sendCookie = false) => {
  // オブジェクトをstring化してクエリパラメータにする
  let queryString = new URLSearchParams(queries).toString();
  if (queryString.length > 1) { queryString = `?${queryString}`; }

  const params = {
    method: 'GET',
    mode: 'cors',
    credentials: sendCookie ? 'include' : 'omit',
    headers: {},
  };

  const fetchPromise = fetch(`${BASE_URL}${url}${queryString}`, params);
  return formatResponse(fetchPromise);
};

// POSTリクエスト
// ex) post(url='/trackingtag', body={
//       device: 'pc',
//       url: 'https://banusy.dmm.com/',
//       login: 0,
//       options: {},
//     }, sendCookie=true)
export const post = async (url = '', body = {}, sendCookie = false) => {
  const params = {
    method: 'POST',
    mode: 'cors',
    credentials: sendCookie ? 'include' : 'omit',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
    },
    body: JSON.stringify(body),
  };

  const fetchPromise = fetch(`${BASE_URL}${url}`, params);
  return formatResponse(fetchPromise);
};
