import React from 'react';
import PropTypes from 'prop-types';

import BackToGoodsPageButton from './ReviewBackToGoodsPageButton';

// レビュー受付開始前ページで表示するメインコンテンツ
const ReviewIsConfirming = ({ contentURL }) => (
  <div id="w">
    <div id="review-post" className="pfReview__page--complete pfReview__page--write pfReview">
      <div className="pfReview--inner">

        <h1 className="pfReview__title">この商品のレビューは受付開始前です</h1>
        <div className="pfReview__lead">
          販売開始までお待ちください。
        </div>

        <div className="pfReview__btn">
          <BackToGoodsPageButton
            contentURL={contentURL}
          />
        </div>

      </div>
    </div>
  </div>
);

ReviewIsConfirming.propTypes = {
  contentURL: PropTypes.string.isRequired,
};

export default ReviewIsConfirming;
