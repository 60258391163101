import React from 'react';
import { withRouter } from 'react-router';
import ReviewCommonParcelButton from './ReviewCommonParcelButton';
import { requestEventI3Complete } from '../../utils/trackingtag';

// 商品詳細画面に戻るボタンのコンポーネント
const RequestReviewList = ({ requestPurchaseReviewAPI, reviewerID, floor }) => {
  if (!requestPurchaseReviewAPI) {
    return null;
  }

  if (requestPurchaseReviewAPI && requestPurchaseReviewAPI.product_list.length === 0) {
    return (
      <ReviewCommonParcelButton
        reviewerID={reviewerID}
      />
    );
  }
  const analytics = (elem, contentId, nextUrl) => {
    let nextContentId = '';
    let nextFloor = '';
    const contentIdBefore = 'cid=';
    const contentIdAfter = '&floor';
    const floorBefore = '&floor=';
    const floorAfter = '&service_name';
    const contentIdBeforeIdx = nextUrl.indexOf(contentIdBefore);
    const contentIdAfterIdx = nextUrl.lastIndexOf(contentIdAfter);
    const floorBeforeIdx = nextUrl.indexOf(floorBefore);
    const floorAfterIdx = nextUrl.lastIndexOf(floorAfter);

    // 次ページのcontent_id
    if (contentIdBeforeIdx >= 0 && contentIdAfterIdx >= 0) {
      // eslint-disable-next-line max-len
      nextContentId = nextUrl.substring(contentIdBeforeIdx + contentIdBefore.length, contentIdAfterIdx);
    }
    // 次ページのfloor名
    if (floorBeforeIdx >= 0 && floorAfterIdx >= 0) {
      nextFloor = nextUrl.substring(floorBeforeIdx + floorBefore.length, floorAfterIdx);
    }
    requestEventI3Complete(elem, contentId, `${contentId}/${floor}`, `${nextContentId}/${nextFloor}`);
  };


  return (
    <div className="pfReview__purchaseListArea">
      <h2>こちらの商品のレビューも記載しませんか？</h2>
      <div className="pfReview__purchaseListWrap">
        <p className="pfReview__purchaseListSup">※既にレビュー記載済みの商品が表示される場合があります。</p>
        <ul className="pfReview__purchaseList">
          {Object.keys(requestPurchaseReviewAPI.product_list).map((key) => {
            if (requestPurchaseReviewAPI.product_list[key].image) {
              return (
                <li key={key} className="pfReview__purchaseItem">
                  <a
                    className="pfReview__purchaseItemLink"
                    href={requestPurchaseReviewAPI.product_list[key].write_url}
                    onClick={() => analytics(
                      'nextReview',
                      requestPurchaseReviewAPI.content_id,
                      requestPurchaseReviewAPI.product_list[key].write_url,
                    )
                    }
                  >
                    <span className="pfReview__purchaseItemImg">
                      <img
                        src={requestPurchaseReviewAPI.product_list[key].image}
                        alt=""
                      />
                    </span>
                    <span className="pfReview__purchaseItemTitle">
                      {requestPurchaseReviewAPI.product_list[key].title}
                    </span>
                    <span className="pfReview__purchaseItemBtn">
                      レビューを書く
                    </span>
                  </a>
                </li>
              );
            }
            return false;
          })}
        </ul>

        <ReviewCommonParcelButton
          reviewerID={reviewerID}
        />
      </div>
    </div>
  );
};

export default withRouter(RequestReviewList);
