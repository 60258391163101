import React, { useState } from 'react';
import PropTypes from 'prop-types';

import ReviewCreateFormStarButton from './ReviewCreateFormStarButton';

// 星五個のコンポーネント
const ReviewCreateFormStarList = ({ starID, setStarID }) => {
  // マウスオーバーされた星のID
  const [mouseOveredStarID, setMouseOveredStarID] = useState(0);
  // 星にマウスオーバーされているか(bool)
  const [isMouseOveredOnList, setIsMouseOveredOnList] = useState(false);

  const StarList = [];
  for (let id = 1; id <= 5; id++) {
    StarList.push(
      <ReviewCreateFormStarButton
        key={id}
        id={id}
        clickedStarID={starID}
        setClickedStarID={setStarID}
        mouseOveredStarID={mouseOveredStarID}
        setMouseOveredStarID={setMouseOveredStarID}
        isMouseOveredOnList={isMouseOveredOnList}
        setIsMouseOveredOnList={setIsMouseOveredOnList}
      />,
    );
  }

  return (
    <>
      { StarList }
    </>
  );
};

ReviewCreateFormStarList.propTypes = {
  starID: PropTypes.number.isRequired,
  setStarID: PropTypes.func.isRequired,
};


export default ReviewCreateFormStarList;
