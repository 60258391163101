import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import Layout from '../../layouts';
import ReviewCreate from '../../components/review/ReviewCreate';
import GetLoginCheckReq from '../../api/logincheck/logincheck';
import { GetMaterialsReq, CreateReview, GetPurchaseList } from '../../api/review';
import { IsAdultPage, GetDeviceType } from '../../utils/page';
import {
  REVIEW_STATUS_PUBLISHED,
  REVIEW_STATUS_CONFIRMING,
  REVIEWER_STATUS_BLACK,
  BROWSER_TYPE_WEB,
  REVIEW_STATUS_RELATION,
} from '../../constants';
import { CheckNicknameAvailableReq } from '../../api/reviewer';


// レビュー投稿画面
const Create = ({ history, location }) => {
  const [materials, setMaterials] = useState(null);
  const [purchaseListMaterials, setPurchaseListMaterials] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // ログインチェックAPIにリクエストする関数
  const requestLoginCheckAPI = async () => {
    // ログインチェックAPIからログイン状況を取得
    const res = await GetLoginCheckReq();
    return res.is_login;
  };

  // クエリパラメータを取得
  const qs = queryString.parse(location.search);

  // レビュー投稿APIにリクエストする関数
  //  -> 投稿成功時：true
  const requestCreateReviewAPI = async (starNum, nickname, title, text, isExposure) => {
    const review = {
      content_id: qs.cid,
      shop_name: materials.content.shop_name,
      title,
      rating: starNum,
      text,
      is_exposure: isExposure,
    };
    const device = GetDeviceType();

    const res = await CreateReview(
      nickname || ' ', // 空だとエラーになるのでニックネームがないときはスペースを代入
      review,
      device,
      BROWSER_TYPE_WEB, // 固定値
    );

    return res.is_success;
  };

  const requestCheckNicknameAPI = async (nickname) => {
    const res = await CheckNicknameAvailableReq(nickname);
    return res.is_available;
  };

  useEffect(() => {
    const requestGetMaterialsAPI = async (contentID, shopName, serviceName) => {
      const res = await GetMaterialsReq(contentID, shopName, serviceName);

      // データの取得に失敗した場合はサーバエラーページにリダイレクト
      // FIXME_201910: エラーかどうかをいっぱつで判断できるレスポンスが欲しい（BFF）
      if (res.error) {
        history.push('/server-error');
        return;
      }

      // Ban されたレビュアーの場合は Ban 画面にリダイレクト
      if (res.reviewer.status === REVIEWER_STATUS_BLACK) {
        history.push('/ban');
        return;
      }

      // レビューのステータスに応じてリダイレクト
      switch (res.review.publish_status) {
        // 既に公開済みの場合は編集画面にリダイレクト
        case REVIEW_STATUS_PUBLISHED:
          history.push(`/update?cid=${qs.cid}&floor=${qs.floor}`);
          return;
        // 承認待ちの場合は投稿時用の承認待ち画面にリダイレクト
        case REVIEW_STATUS_CONFIRMING:
          history.push('/create/confirming');
          return;
        // 相互リンクしているコンテンツに対するレビューを既に投稿済みの場合はレビュー投稿済み画面にリダイレクト
        case REVIEW_STATUS_RELATION:
          history.push({
            pathname: '/create/exists',
            state: {
              contentURL: res.content.content_url,
            },
          });
          return;
        default:
          break;
      }

      // 投稿画面表示に必要な情報を取得できなかった場合
      // FIXME_201910: 値を取得できたかどうかをいっぱつで判断できるレスポンスが欲しい（BFF）
      if (res.content.title === '') {
        // 404指定のパスを決めてなかったぽいので、仮にこうしました
        history.push('/not-found');
        return;
      }

      // 商品が購入受付開始前の場合、レビュー受付開始前画面にリダイレクト
      // FEATURE_201911: DMMAPI脱却時に、レビューステータスによるリダイレクト処理の前に購入受付前かどうかの処理を入れる
      if (!res.content.is_released) {
        history.push({
          pathname: '/content/preparing',
          state: {
            contentURL: res.content.content_url,
          },
        });
        return;
      }

      setMaterials(res);
      setIsLoading(false);
    };

    requestGetMaterialsAPI(qs.cid, qs.floor, qs.service_name);

    // NOTE_201910: 下記 ESLint 無効化について、history を依存関係に含める必要があるが、
    //              本処理（requestLoginCheckAPI()）には、本質的に関係ないので無視
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // 投稿完了ページで表示する購入済リスト一覧を取得。投稿完了ページへ遷移時に取得データを送付
    const requestGetReviewerReqAPI = async (contentID, attribute) => {
      const resPurchaseList = await GetPurchaseList(contentID, attribute);
      if (resPurchaseList.error) {
        setPurchaseListMaterials('');
        return;
      }
      setPurchaseListMaterials(resPurchaseList);
    };
    const attribute = IsAdultPage() ? 'adult' : 'general';
    requestGetReviewerReqAPI(qs.cid, attribute);
  }, [history, qs.cid, qs.floor, qs.service_name]);

  // NOTE_201910: ローディング画面を作る必要が生じた場合は、下記リンクを参考
  //  https://reactjs.org/docs/react-api.html#reactlazy
  if (isLoading) {
    return null;
  }

  // レビュアーおよびレビューのステータスに応じてリダイレクト
  if (materials) {
    // localStorageから書き込み途中のレビュー内容を読み込む
    const localStorageReviewKey = qs.cid + qs.floor;
    const reviewJsonData = localStorage.getItem(localStorageReviewKey);
    if (reviewJsonData) {
      const reviewObj = JSON.parse(reviewJsonData);
      materials.review.rating = reviewObj.rating;
      materials.review.title = reviewObj.title;
      materials.review.text = reviewObj.text;
      materials.review.exposure = reviewObj.exposure;
    }
  }

  return (
    <Layout
      subTitle="レビューの投稿"
    >
      <ReviewCreate
        materials={materials}
        requestLoginCheckAPI={requestLoginCheckAPI}
        requestCreateReviewAPI={requestCreateReviewAPI}
        requestCheckNicknameAPI={requestCheckNicknameAPI}
        purchaseListMaterials={purchaseListMaterials}
      />
    </Layout>
  );
};

Create.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(Create);
