import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';

import { IsAdultPage } from '../../utils/page';
import { HOST_COM, HOST_COJP } from '../../constants';
import { requestEventI3Complete, requestEventI3Create } from '../../utils/trackingtag';


// 自身のレビュー情報画面へ戻るボタンのコンポーネント
const ReviewBackToMyReviewsPageButton = ({ reviewerID }) => {
  const host = IsAdultPage() ? HOST_COJP : HOST_COM;
  const myReviewerPageURL = `${host}/review/-/manage/=/id=${reviewerID}/type=my/`;
  const analytics = (elem) => {
    if (window.location.href.match(/complete/)) {
      requestEventI3Complete(elem);
    } else if (window.location.href.match(/create/)) {
      requestEventI3Create(elem);
    }
  };

  return (
    <p>
      {/* TODO: ローカル環境ではテストできない（localhost:3000/ に続く形でパスが指定される）ため DEV 以上の環境で動作確認必須 */}
      {/* react-router-dom の Link タグを使用すると URL に差分がないと遷移処理が走らない。
          よって、『あなたのレビュー情報へ』ボタンを押しても反応していないように見えるため a タグで遷移させる。 */}
      <a
        href={myReviewerPageURL}
        className="pfReview__btn--secondary"
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => analytics('mypageLink')}
      >
        <span>あなたのレビュー情報へ</span>
      </a>
    </p>
  );
};

ReviewBackToMyReviewsPageButton.propTypes = {
  reviewerID: PropTypes.number.isRequired,
};

export default withRouter(ReviewBackToMyReviewsPageButton);
