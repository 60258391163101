import React from 'react';
import PropTypes from 'prop-types';

import BackToMyReviewsPageButton from './ReviewBackToMyReviewsPageButton';
import ReviewTermsLink from './ReviewTermsLink';
import { NO_REVIEWER_ID } from '../../constants';

// レビュー承認待ち画面で表示するメインコンテンツ
const ReviewIsConfirming = ({ reviewerID }) => (
  <div id="w">
    <div id="review-post" className="pfReview__page--complete pfReview__page--write pfReview">
      <div className="pfReview--inner">

        <h1 className="pfReview__title">この商品のレビューはスタッフ確認中です</h1>
        <div className="pfReview__lead">
          レビュー公開までしばらくお待ちください。
          <div className="pfReview__lead--note">
            <p>・通常1週間ほどで公開されますが、それ以上かかることがあります。</p>
            <p>
              ・
              <ReviewTermsLink />
              に反する場合は、公開されないことがあります。
            </p>
          </div>
        </div>

        {
          reviewerID !== NO_REVIEWER_ID
            ? (
              <div className="pfReview__btn">
                <BackToMyReviewsPageButton
                  reviewerID={reviewerID}
                />
              </div>
            )
            : null
        }

      </div>
    </div>
  </div>
);

ReviewIsConfirming.propTypes = {
  reviewerID: PropTypes.number.isRequired,
};

export default ReviewIsConfirming;
