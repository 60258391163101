import React from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';

import Layout from '../../layouts';
import ReviewContentIsPreparing from '../../components/review/ReviewContentIsPreparing';

// レビュー受付開始前画面
const IsPreparing = ({ history, location }) => {
  // 前画面からデータ（contentURL）が渡されていない場合は NotFound 画面へリダイレクト
  if (!location.state) {
    history.push('/not-found');
    return null;
  }

  return (
    <Layout
      subTitle="レビューの投稿"
    >
      <ReviewContentIsPreparing
        contentURL={location.state.contentURL}
      />
    </Layout>
  );
};

IsPreparing.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    state: PropTypes.shape({
      contentURL: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withRouter(IsPreparing);
