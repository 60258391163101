import React from 'react';
import PropTypes from 'prop-types';
import BackToGoodsPageButton from './ReviewBackToGoodsPageButton';
import RequestReviewList from './RequestReviewList';
import ReviewTermsLink from './ReviewTermsLink';
import ReviewCommonCampaign from './ReviewCommonCampaign';

// レビュー投稿完了画面で表示するメインコンテンツ
const ReviewCreateCompleteMessage = ({
  contentURL, reviewerID, materials, floor,
}) => (
  <div id="w">
    <div
      id="review-post"
      className="pfReview__page--complete pfReview__page--write pfReview"
    >
      <div className="pfReview--inner">
        <h1 className="pfReview__title">投稿ありがとうございました！</h1>

        <p className="pfReview__lead">
          投稿いただいたレビューは、スタッフ確認後に通常1週間ほどで公開されます。
        </p>

        {/* 購入済未レビュー商品を表示 */}
        <RequestReviewList
          requestPurchaseReviewAPI={materials}
          reviewerID={reviewerID}
          floor={floor}
        />

        <div className="pfReview__btn --complete">
          {
                contentURL
                  ? (
                    <BackToGoodsPageButton
                      contentURL={contentURL}
                    />
                  ) : null
              }
        </div>

        <div className="pfReview__note">
          <ul>
            <li>
              <ReviewTermsLink />
              に反する場合は、公開されないことがあります。
            </li>
          </ul>
        </div>

        <ReviewCommonCampaign />
      </div>
    </div>
  </div>
);

ReviewCreateCompleteMessage.propTypes = {
  contentURL: PropTypes.string.isRequired,
  reviewerID: PropTypes.number.isRequired,
};

export default ReviewCreateCompleteMessage;
