import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import queryString from 'query-string';

import Layout from '../../layouts';
import ReviewUpdate from '../../components/review/ReviewUpdate';
import GetLoginCheckReq from '../../api/logincheck/logincheck';
import {
  GetMaterialsReq,
  CreateReview,
  GetPurchaseList,
} from '../../api/review';
import { IsAdultPage, GetDeviceType } from '../../utils/page';
import {
  REVIEW_STATUS_CONFIRMING, REVIEW_STATUS_NONE, REVIEWER_STATUS_BLACK, BROWSER_TYPE_WEB,
} from '../../constants';

// レビュー編集画面
const Update = ({ history, location }) => {
  const [materials, setMaterials] = useState(null);
  const [purchaseListMaterials, setPurchaseListMaterials] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  // クエリパラメータを取得
  const qs = queryString.parse(location.search);

  useEffect(() => {
    const requestGetMaterialsAPI = async (contentID, shopName, serviceName) => {
      const res = await GetMaterialsReq(contentID, shopName, serviceName);

      // データの取得に失敗した場合はサーバエラーページにリダイレクト
      // FIXME_201910: エラーかどうかをいっぱつで判断できるレスポンスが欲しい（BFF）
      if (res.error) {
        history.push('/server-error');
        return;
      }

      // Ban されたレビュアーの場合は Ban 画面にリダイレクト
      if (res.reviewer.status === REVIEWER_STATUS_BLACK) {
        history.push('/ban');
        return;
      }

      // レビューのステータスに応じてリダイレクト
      switch (res.review.publish_status) {
        // 未投稿の場合は投稿画面にリダイレクト
        case REVIEW_STATUS_NONE:
          history.push(`/create?cid=${qs.cid}&floor=${qs.floor}`);
          return;
        // 承認待ちの場合は編集時用の承認待ち画面にリダイレクト
        case REVIEW_STATUS_CONFIRMING:
          history.push('/update/confirming');
          return;
        default:
          break;
      }

      // 投稿画面表示に必要な情報を取得できなかった場合
      // FIXME_201910: 値を取得できたかどうかをいっぱつで判断できるレスポンスが欲しい（BFF）
      if (res.content.title === '') {
        // 404指定のパスを決めてなかったぽいので、仮にこうしました
        history.push('/not-found');
        return;
      }

      setMaterials(res);
      setIsLoading(false);
    };

    requestGetMaterialsAPI(qs.cid, qs.floor, qs.service_name);

    // NOTE_201910: 下記 ESLint 無効化について、history を依存関係に含める必要があるが、
    //              本処理（requestLoginCheckAPI()）には、本質的に関係ないので無視
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // 投稿完了ページで表示する購入済リスト一覧を取得。投稿完了ページへ遷移時に取得データを送付
    const requestGetReviewerReqAPI = async (contentID, attribute) => {
      const resPurchaseList = await GetPurchaseList(contentID, attribute);
      if (resPurchaseList.error) {
        setPurchaseListMaterials('');
        return;
      }
      setPurchaseListMaterials(resPurchaseList);
    };
    const attribute = IsAdultPage() ? 'adult' : 'general';
    requestGetReviewerReqAPI(qs.cid, attribute);
  }, [history, qs.cid, qs.floor, qs.service_name]);

  // ログインチェックAPIにリクエストする関数
  const requestLoginCheckAPI = async () => {
    // ログインチェックAPIからログイン状況を取得
    const res = await GetLoginCheckReq();
    return res.is_login;
  };

  // レビュー編集APIにリクエストする関数
  //  -> 投稿成功時：true
  const requestUpdateReviewAPI = async (starNum, nickname, title, text, isExposure) => {
    const review = {
      content_id: qs.cid,
      shop_name: materials.content.shop_name,
      title,
      rating: starNum,
      text,
      is_exposure: isExposure,
    };
    const device = GetDeviceType();

    const res = await CreateReview(
      nickname || ' ', // 空だとエラーになるのでニックネームがないときはスペースを代入
      review,
      device,
      BROWSER_TYPE_WEB, // 固定値
    );

    return res.is_success;
  };

  // NOTE_201910: ローディング画面を作る必要が生じた場合は、下記リンクを参考
  //  https://reactjs.org/docs/react-api.html#reactlazy
  if (isLoading) {
    return null;
  }

  return (
    <Layout subTitle="レビューの編集">
      <ReviewUpdate
        materials={materials}
        requestLoginCheckAPI={requestLoginCheckAPI}
        requestUpdateReviewAPI={requestUpdateReviewAPI}
        purchaseListMaterials={purchaseListMaterials}
      />
    </Layout>
  );
};

Update.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
  }).isRequired,
};

export default withRouter(Update);
