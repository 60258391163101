import React, {
  useState, useEffect, useRef, useReducer,
} from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useDebounce } from 'use-debounce';

import {
  REVIEW_CREATE_FORM_INPUT_MIN_LENGTH,
  REVIEW_CREATE_FORM_INPUT_MAX_LENGTH,
  REVIEW_STATUS_NONE,
} from '../../constants/index';
import ReviewCreateFormStarList from './ReviewCreateFormStarList';
import ReviewCreateFormInput from './ReviewCreateFormInput';
import ReviewCreateFormTextArea from './ReviewCreateFormTextArea';
import ReviewTermsLink from './ReviewTermsLink';
import { RedirectToLoginPage } from '../../utils/page';
import ReviewCommonModal from './ReviewCommonModal';

// レビュー共通フォーム（投稿/編集）
const ReviewCommonForm = ({
  history, location, materials, requestLoginCheckAPI, requestSubmitReviewAPI,
  requestCheckNicknameAPI, purchaseListMaterials,
}) => {
  const [clickedStarID, setClickedStarID] = useState(0); // クリックされた星のID
  const [nickname, setNickname] = useState('');
  const [nicknameErrorMsg, setNicknameErrorMsg] = useState('');
  const [title, setTitle] = useState('');
  const [titleErrorMsg, setTitleErrorMsg] = useState('');
  const [text, setText] = useState(''); // レビューコメントのことですが既存ページのユビキタス言語に合わせて text
  const [textErrorMsg, setTextErrorMsg] = useState('');
  const [isExposure, setIsExposure] = useState(false); // exposure（ネタバレ）を含む場合は true
  const [openModal, setOpenModal] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const isAvailableNickname = useRef(false);

  // 強制再レンダリング用関数
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  // 入力が始まると『投稿するボタン』を disabled 状態に移行させる機能付きの setNickname()
  const setNicknameWithButtonDisabled = (name) => {
    isAvailableNickname.current = false;
    setNickname(name);
    forceUpdate();
  };

  // materials から各データを取得
  const { review, reviewer, content } = materials;

  // ニックネームが既に存在する場合は isAvailableNickname が常に true
  if (reviewer.nickname) {
    isAvailableNickname.current = true;
  }

  // クエリパラメータを取得
  const qs = queryString.parse(location.search);

  // Debounceを実行する際に対象とする変数
  // (第一引数のstateが変化して第二引数[ミリ秒]経過後、stateを変更)
  const [debounceNickname] = useDebounce(nickname, 1000);
  const [debounceTitle] = useDebounce(title, 1000);
  const [debounceText] = useDebounce(text, 1000);

  // 指定されたレビューのステータスを設定
  // NOTE_201910: review.publish_status には絶対に値が入っている（デフォルト：'none'）
  const isUpdate = review.publish_status !== REVIEW_STATUS_NONE;

  // localStorageのレビュー内容のキー
  const localStorageReviewKey = qs.cid + qs.floor;

  // propsの値を反映
  useEffect(() => {
    setClickedStarID(review.rating);
    setTitle(review.title);
    setText(review.text);
    setIsExposure(review.is_exposure);
  }, [review.rating, review.title, review.text, review.is_exposure]);

  // debounceによりローカルストレージに保存する
  useEffect(() => {
    // 編集画面ではlocalStorageに書き込み途中のレビュー内容を保存しない
    if (isUpdate) {
      return;
    }

    // localStorageに書き込み途中のレビュー内容を保存
    const item = {
      rating: clickedStarID,
      title: debounceTitle,
      text: debounceText,
      exposure: isExposure,
    };
    if (clickedStarID || debounceTitle || debounceText || isExposure) {
      localStorage.setItem(localStorageReviewKey, JSON.stringify(item));
    }
    // NOTE_201910: 下記 ESLint 無効化について、localStorageReviewKey を依存関係に含める必要があるが、
    //              本処理には、本質的に関係ないので無視
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickedStarID, debounceTitle, debounceText, isExposure]);

  useEffect(() => {
    // タイトルとコメントが入力されている、かつ、既にエラーメッセージが存在する場合、
    // 本コンポーネントではエラー文をセットせずに Input および TextArea 用コンポーネントにエラー文セットを任せる
    if (title && text && (textErrorMsg || titleErrorMsg)) return;

    // タイトルまたはテキストがどちらか一方だけ入力された場合のエラー文セット
    if (!title && text) {
      setTitleErrorMsg(`${REVIEW_CREATE_FORM_INPUT_MIN_LENGTH.title}文字以上入力してください。`);
    } else if (title && !text) {
      setTextErrorMsg(`${REVIEW_CREATE_FORM_INPUT_MIN_LENGTH.text}文字以上入力してください。`);
    } else {
      setTitleErrorMsg('');
      setTextErrorMsg('');
    }
  }, [title, text, textErrorMsg, titleErrorMsg]);

  // debounceにより、ニックネーム利用可否をチェックする
  useEffect(() => {
    const CheckNickname = async (name) => {
      const isAvailable = await requestCheckNicknameAPI(name);
      isAvailableNickname.current = isAvailable;
      if (!isAvailable) {
        setNicknameErrorMsg('ご指定のニックネームは利用できません。');
      } else {
        forceUpdate();
      }
    };

    if (debounceNickname) {
      CheckNickname(debounceNickname);
    }
    // NOTE_201910: 下記 ESLint 無効化について、requestCheckNicknameAPI を依存関係に含める必要があるが、
    //              本処理には、本質的に関係ないので無視
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceNickname]);

  // 投稿ボタンハンドラ
  const submitButtonHandler = async () => {
    // 2重投稿防止用
    setIsClicked(true);

    // 未ログインの場合はログイン画面にリダイレクト
    const isLogin = await requestLoginCheckAPI();
    if (!isLogin) {
      RedirectToLoginPage();
      return;
    }

    const success = await requestSubmitReviewAPI(clickedStarID, nickname, title, text, isExposure);
    if (success) {
      // localStorageから書き込み途中のレビュー内容を削除する
      localStorage.removeItem(localStorageReviewKey);

      // Submit 成功時は 投稿/編集完了画面に遷移
      const to = isUpdate ? 'update' : 'create';
      history.push({
        pathname: `/${to}/complete`,
        state: {
          contentURL: content.content_url,
          contentId: qs.cid,
          floor: qs.floor,
          materials: purchaseListMaterials,
          // TODO_201910: DMMAPI 脱却完了後は CreateReview API の戻り値に含まれるレビュアーIDを渡す
          // reviewerID: reviewer.ID,
        },
      });
    } else {
      // レビュー投稿/編集に失敗した場合にモーダルを表示
      setOpenModal(true);
    }
  };

  // 入力値のバリデーションを行う関数
  //  - true : バリデーションエラーあり
  //  - false: バリデーションエラーなし
  const validateInputs = () => {
    // 星をクリックしていない場合はエラー
    if (clickedStarID === 0) {
      return true;
    }

    // ニックネーム登録が必要なときにニックネームが入力されていない場合はエラー
    if (!reviewer.nickname && !nickname) {
      return true;
    }

    // レビュータイトルが入力されているのにレビューコメントが入力されていない場合はエラー
    if (title && !text) {
      return true;
    }

    // レビューコメントが入力されているのにレビュータイトルが入力されていない場合はエラー
    if (text && !title) {
      return true;
    }

    // 各入力値のどれかでバリデーションエラーが発生している場合はエラー
    return !!nicknameErrorMsg || !!titleErrorMsg || !!textErrorMsg;
  };

  // 緑チェック（バリデーションエラー無し）を表示する場合は class に "is-checked" を付与
  const validNicknameClassName = (nickname && !nicknameErrorMsg) ? 'is-checked' : '';
  const validTitleClassName = (title && !titleErrorMsg) ? 'is-checked' : '';
  const validTextClassName = (text && !textErrorMsg) ? 'is-checked' : '';

  // ニックネームが利用不可であるときにフォームを赤枠にするためのフラグ
  const invalidNicknameClassName = (nickname && nicknameErrorMsg) ? 'is-invalid' : '';

  return (
    <section className="pfReview__form">
      {/* 星評価 */}
      <div className="pfReview__area--evaluate" id="fn-area-star">
        <span
          className="pfReview__form--label"
        >
          評価
          <span className="red">*</span>
        </span>
        <div className="pfReview__form--star-wrap" id="fn-box-star">
          <ReviewCreateFormStarList
            starID={clickedStarID}
            setStarID={setClickedStarID}
          />
        </div>
      </div>

      {/* ニックネーム */}
      { /* 新規投稿画面 かつ ニックネームが決まっていない場合にのみ入力フォームを表示 */
        !isUpdate && !reviewer.nickname && (
        <>
          <div className={`pfReview__area--nickname ${invalidNicknameClassName}`} id="fn-area-nickname">
            {/* 入力フォームラベル */}
            <span
              htmlFor="title"
              id="review_title"
              className="pfReview__form--label fn-label-custom"
            >
              {/* &ensp; はスペースを表す特殊文字 */}
              &ensp;ニックネーム
              <span className="red">*</span>
              &ensp;
            </span>

            <div className="pfReview__form--wrap">
              <ReviewCreateFormInput
                kind="nickname"
                setInputString={setNicknameWithButtonDisabled}
                setErrorMsg={setNicknameErrorMsg}
              />
            </div>

            <div className="pfReview__valid fn-box-count is-active">
              <div className={`pfReview__form--wrap fn-check ${validNicknameClassName}`}>
                {/* バリデーションエラー情報を表示 */}
                {nicknameErrorMsg && (
                  <p className="pfReview__valid--note fn-note">
                    {nicknameErrorMsg}
                  </p>
                )}

                {/* 文字数カウンター */}
                {nickname && (
                  <p className="pfReview__valid--count fn-nickname-input">
                    <span className="pfReview__valid--count-num fn-count">
                      {nickname.length}
                    </span>
                    /
                    {REVIEW_CREATE_FORM_INPUT_MAX_LENGTH.nickname}
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* ニックネームに関する注意書き */}
          <p className="pfReview--note pfReview__valid--note-nickname fn-note">
            ※レビュー、マーケットプレイス、オークションで使用します。
          </p>
          <br />
        </>
        )}

      {/* タイトル */}
      <div
        className={`pfReview__area--title ${titleErrorMsg ? 'is-invalid' : ''}`}
        id="fn-area-title"
      >
        {/* 入力フォームラベル */}
        <span
          htmlFor="title"
          id="review_title"
          className="pfReview__form--label fn-label-custom"
        >
          {/* &ensp; はスペースを表す特殊文字 */}
          &ensp;タイトル&ensp;
        </span>

        <div className="pfReview__form--wrap">
          <ReviewCreateFormInput
            kind="title"
            setInputString={setTitle}
            setErrorMsg={setTitleErrorMsg}
            defaultValue={review.title}
          />
        </div>

        <div className="pfReview__valid fn-box-count is-active">
          <div className={`pfReview__form--wrap fn-check ${validTitleClassName}`}>
            {/* バリデーションエラー情報を表示 */}
            {(() => {
              // バリデーションエラー情報の表示
              if (titleErrorMsg) {
                return (
                  <p className="pfReview__valid--note fn-note">
                    {titleErrorMsg}
                  </p>
                );
              }

              return null;
            })()}

            {/* 文字数カウンター */}
            {title && (
              <p className="pfReview__valid--count">
                <span className="pfReview__valid--count-num fn-count">
                  {title.length}
                </span>
                /
                {REVIEW_CREATE_FORM_INPUT_MAX_LENGTH.title}
              </p>
            )}
          </div>
        </div>
      </div>

      {/* コメント */}
      <div
        className={`pfReview__area--comment ${textErrorMsg ? 'is-invalid' : ''}`}
        id="fn-area-comment"
      >
        {/* 入力フォームラベル */}
        <span
          htmlFor="title"
          id="review_title"
          className="pfReview__form--label fn-label-custom"
        >
          {/* &ensp; はスペースを表す特殊文字 */}
          &ensp;コメント&ensp;
        </span>

        <div className="pfReview__form--wrap">
          <ReviewCreateFormTextArea
            kind="text"
            rows="8"
            cols="30"
            setInputString={setText}
            setErrorMsg={setTextErrorMsg}
            defaultValue={review.text}
          />
        </div>

        <div className="pfReview__valid fn-box-count is-active">
          <div className={`pfReview__form--wrap fn-check ${validTextClassName}`}>
            {/* バリデーションエラー情報を表示 */}
            {(() => {
              // バリデーションエラー情報の表示
              if (textErrorMsg) {
                return (
                  <p className="pfReview__valid--note fn-note">
                    {textErrorMsg}
                  </p>
                );
              }

              return null;
            })()}

            {/* 文字数カウンター */}
            {text && (
              <span className="pfReview__valid--count-text-box">
                <span className="pfReview__valid--count-text" />
                <span className="pfReview__valid--count-num fn-count">
                  {text.length}
                </span>
                /
                {REVIEW_CREATE_FORM_INPUT_MAX_LENGTH.text}
              </span>
            )}
          </div>
        </div>
      </div>

      {/* ネタバレ チェックボックス */}
      <div className="pfReview__area--exposure">
        {
          isExposure
            ? (
              // eslint-disable-next-line max-len
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <span
                className="pfReview__form--checkbox-label is-checked"
                id="fn-exposure-checkbox"
                onClick={() => setIsExposure(false)}
              >
                <span
                  className="pfReview__form--checkbox-text"
                  id="review_exposure"
                >
                  ネタバレ
                </span>
              </span>
            )
            : (
              // eslint-disable-next-line max-len
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
              <span
                className="pfReview__form--checkbox-label"
                id="fn-exposure-checkbox"
                onClick={() => setIsExposure(true)}
              >
                <span
                  className="pfReview__form--checkbox-text"
                >
                  ネタバレ
                </span>
              </span>
            )
        }
        <p
          className="pfReview--note"
          id="fn-noteExposure"
        >
          スタッフの判断により『ネタバレ』とさせていただく場合があります。
        </p>
      </div>

      {/* 編集に関する注意書き */}
      {isUpdate && <p className="pfReview__error--edit">※レビューの編集をすると、それまでに投票された参考になった数が0になります。</p>}

      {/* 投稿ボタン */}
      <div className="pfReview__area--submit">
        <button
          type="button"
          className="pfReview__btn--primary"
          id="next-btn"
          disabled={!isAvailableNickname.current || validateInputs() || isClicked}
          onClick={submitButtonHandler}
        >
          投稿する
        </button>

        {/* レビュー全般に関する注意書き */}
        <p className="pfReview__note--term">
          <ReviewTermsLink />
          に同意の上、投稿してください。
        </p>
      </div>

      <ReviewCommonModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        isUpdate={isUpdate}
      />
    </section>
  );
};

ReviewCommonForm.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  materials: PropTypes.shape({
    review: PropTypes.shape({
      title: PropTypes.string,
      text: PropTypes.string,
    }).isRequired,
    reviewer: PropTypes.shape({
      nickname: PropTypes.string,
    }).isRequired,
    content: PropTypes.shape({
      content_url: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  requestLoginCheckAPI: PropTypes.func.isRequired,
  requestSubmitReviewAPI: PropTypes.func.isRequired,
  requestCheckNicknameAPI: PropTypes.func.isRequired,
};

export default withRouter(ReviewCommonForm);
