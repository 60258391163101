import React from 'react';
import PropTypes from 'prop-types';

// エラーページ用の静的なフッター（NaviAPIから取得したフッターではない）
const ErrorFooter = ({ isComPage }) => {
  const domain = isComPage ? 'com' : 'co.jp';

  return (
    <footer className="_n4v1-footer">
      <div className="_n4v1-mainfooter-sp">
        {!isComPage && (
          <span>
            <a href="https://terms.dmm.co.jp/profile/" target="_self">
              ・会社概要
            </a>
          </span>
        )}
        <span>
          <a
            href={`https://terms.dmm.${domain}/member/`}
            target="_self"
            rel="nofollow"
          >
            ・会員規約
          </a>
        </span>
        <span>
          <a
            href={`https://terms.dmm.${domain}/privacy/`}
            target="_self"
            rel="nofollow"
          >
            ・個人情報保護に関して
          </a>
        </span>
        <span>
          <a
            href={`https://terms.dmm.${domain}/commerce/`}
            target="_self"
            rel="nofollow"
          >
            ・特定商取引法に基づく表示
          </a>
        </span>
        <span><a href={`https://terms.dmm.${domain}/`} target="_self">・規約一覧</a></span>
        {
          isComPage
            ? (
              <span>
                <a
                  href="https://dmm-corp.com/recruit/"
                  /* eslint-disable-next-line react/jsx-no-target-blank */
                  target="_blank"
                  rel="noopener"
                >
                  ・採用情報
                </a>
              </span>
            )
            : null
        }
      </div>
      <div className="_n4v1-copyright-sp">Copyright © since 1998 DMM All Rights Reserved.</div>
    </footer>
  );
};

ErrorFooter.propTypes = {
  isComPage: PropTypes.bool.isRequired,
};

export default ErrorFooter;
