import React from 'react';
import { isMobile } from 'react-device-detect';
import { IsAdultPage } from '../../utils/page';
import bnrReviewCampaignPc from '../../images/common/pc/bnr/bnr_review_campaign.png';
import bnrReviewCampaignSp from '../../images/common/sp/bnr/bnr_review_campaign.png';
import { requestEventI3Complete, requestEventI3Create } from '../../utils/trackingtag';

// レビュー投稿画面で表示する注意書き
const ReviewCommonCampaign = () => {
  const analytics = (elem) => {
    if (window.location.href.match(/complete/)) {
      requestEventI3Complete(elem);
    } else if (window.location.href.match(/create/)) {
      requestEventI3Create(elem);
    }
  };
  return (
    <div className="pfReview--campaign">
      {/* DMM_SP */}
      {/* {!IsAdultPage() && isMobile ? (<img src="" alt="" />) : undefined} */}
      {/* DMM_PC */}
      {/* {!IsAdultPage() && !isMobile ? (<img src="" alt="" />) : undefined} */}
      {/* FANZA_SP */}
      {IsAdultPage() && isMobile ? (
        <>
          <h2>現在開催中のレビューキャンペーン</h2>
          <a
            href="https://special.dmm.co.jp/review/campaign/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => analytics('reviewCampaignLink')}
          >
            <img src={bnrReviewCampaignSp} alt="レビュー投稿キャンペーン" />

          </a>
        </>
      ) : undefined}
      {/* FANZA_PC */}
      {IsAdultPage() && !isMobile ? (
        <>
          <h2>現在開催中のレビューキャンペーン</h2>
          <a
            href="https://special.dmm.co.jp/review/campaign/"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => analytics('reviewCampaignLink')}
          >
            <img src={bnrReviewCampaignPc} alt="レビュー投稿キャンペーン" />

          </a>
        </>
      ) : undefined}
    </div>
  );
};

export default ReviewCommonCampaign;
