import { IsAdultPage } from './page';

// トラッキングに必要なタグをbodyタグの直後に設置
// 参考：https://confl.arms.dmm.com/pages/viewpage.action?pageId=201552262
export const embedTrackingtag = (trackingtag) => {
  // トラッキングタグの設置
  const addDiv = document.createElement('div');
  addDiv.id = 'tracking_area';
  document.body.appendChild(addDiv);
  const parentDiv = document.getElementById('tracking_area');
  let trackingTags = trackingtag.replace(new RegExp('\\r?\\n', 'g'), '');

  // i3タグをセット
  if (trackingTags.match(new RegExp('<!-- i3 tag -->.*?</script>', 'mig'))) {
    parentDiv.insertAdjacentHTML('beforeend', trackingTags.match(new RegExp('(<input\\sid="i3.*?)<script>', 'mi'))[1]);
    const i3Script = document.createElement('script');
    // eslint-disable-next-line prefer-destructuring
    i3Script.innerHTML = trackingTags.match(new RegExp('<script>(.+?)</script>', 'mi'))[1];
    parentDiv.appendChild(i3Script);
    trackingTags = trackingTags.replace(new RegExp('<!-- i3 tag -->.*?</script>', 'mi'), '');
  }

  // gtmタグをセット
  const scripts = trackingTags.match(new RegExp('(<script>|<script src=".*?>).*?</script>', 'mig'));
  if (scripts != null) {
    const scriptDataList = [];
    for (let i = 0; i < scripts.length; i++) {
      if (scripts[i].match(new RegExp('<script>(.+?)</script>', 'mi'))) {
        scriptDataList.push({
          code: scripts[i].match(new RegExp('<script>(.+?)</script>', 'mi'))[1],
          src: '',
          async: false,
        });
      } else if (scripts[i].match(new RegExp('<script src="(.*?)".+?></script>', 'mi'))) {
        const async = !!scripts[i].match(new RegExp('(async)', 'mi'));
        scriptDataList.push({
          code: '',
          src: scripts[i].match(new RegExp('<script src="(.*?)".+?</script>', 'mi'))[1],
          async,
        });
      }
    }

    for (let i = 0; i < scriptDataList.length; i++) {
      const addScript = document.createElement('script');
      if (scriptDataList[i].code !== '') {
        addScript.innerHTML = scriptDataList[i].code;
      } else if (scriptDataList[i].src !== '') {
        addScript.src = scriptDataList[i].src;
        addScript.async = scriptDataList[i].async;
      }
      parentDiv.insertBefore(addScript, null);
    }
  }
};

// I3へリクエストを実行
export const requestI3 = () => {
  window.i3('init', 'i3_3keufo');
  window.i3('create');
  window.i3('send', 'view', 'page');
};

// GTM/GAへリクエストするための発火処理
export const requestGTMGA = (subTitle) => {
  const isAdult = IsAdultPage();
  const pageTitle = subTitle + (isAdult ? ' - FANZA' : ' - DMM.com');
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ event: 'pageViewEvent', pageUrl: window.location.pathname, pageTitle });
};
// I3へイベントタグリクエストを実行(投稿ページ)
export const requestEventI3Create = (elem) => {
  window.i3('create', 'clickCommon');
  window.i3('clickCommon.set', 'detail', {
    via_info: 'click_common_review_review_create',
    via_option: elem || '',
  });
  window.i3('clickCommon.send', 'click', 'click_common');
};

// I3へイベントタグリクエストを実行(投稿完了ページ)
export const requestEventI3Complete = (elem, contentId, current, next) => {
  window.i3('create', 'clickCommon');
  window.i3('clickCommon.set', 'detail', {
    via_info: 'click_common_review_review_create_complete',
    via_option: elem || '',
    via_view_rank: current || '',
    via_show_position: next || '',
  });
  window.i3('clickCommon.add', 'detail', {
    content_id: contentId || '',
  });
  window.i3('clickCommon.send', 'click', 'click_common');
};
