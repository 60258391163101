import React from 'react';
import { IsAdultPage } from '../../utils/page';
import BackToMyReviewsPageButton from './ReviewBackToMyReviewsPageButton';
import { NO_REVIEWER_ID } from '../../constants';
import { requestEventI3Complete, requestEventI3Create } from '../../utils/trackingtag';

// レビュー投稿画面で表示する注意書き
const ReviewCommonParcelButton = ({ reviewerID }) => {
  const analytics = (elem) => {
    if (window.location.href.match(/complete/)) {
      requestEventI3Complete(elem);
    } else if (window.location.href.match(/create/)) {
      requestEventI3Create(elem);
    }
  };
  return (
    <div className="pfReview__btn --parallel">
      <p>
        {IsAdultPage() ? (
          <a
            href="https://www.dmm.co.jp/review/-/ranking/=/term=3month/"
            className="pfReview__btn--secondary"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => analytics('rankingLink')}
          >
            <span>レビューランキングへ</span>
          </a>
        ) : (
          <a
            href="https://www.dmm.com/review/-/ranking/=/term=3month/"
            className="pfReview__btn--secondary"
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => analytics('rankingLink')}
          >
            <span>レビューランキングへ</span>
          </a>
        )}
      </p>

      {
        reviewerID !== NO_REVIEWER_ID ? (
          <BackToMyReviewsPageButton
            reviewerID={reviewerID}
          />
        ) : null
      }
    </div>
  );
};

export default ReviewCommonParcelButton;
