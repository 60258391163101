import React from 'react';

import Layout from '../../layouts';
import PcPageMaintenance from '../../components/common/maintenance/pc/Maintenance';
import SpPageMaintenance from '../../components/common/maintenance/sp/Maintenance';
import { GetDeviceType } from '../../utils/page';
import { DEVICE_TYPE_SP } from '../../constants';

let PageMaintenance = PcPageMaintenance;
let subTitle = 'メンテナンス';

// アクセス元デバイスがspの場合表示するコンポーネントとタイトルを変更
if (GetDeviceType() === DEVICE_TYPE_SP) {
  PageMaintenance = SpPageMaintenance;
  subTitle = 'メンテナンス';
}

// メンテナンスページ
const Maintenance = () => (
  <Layout
    subTitle={subTitle}
  >
    <PageMaintenance />
  </Layout>
);


export default Maintenance;
