import React from 'react';
import PropTypes from 'prop-types';

// レビュー投稿画面で表示する商品情報
const ReviewCreateGoodInfo = ({ goods }) => (
  <section className="pfReview__goods">
    <div className="pfReview__area--image">
      <img src={goods.content_image_src} alt={goods.title} />
    </div>
    <div className="pfReview__area--text">
      <p className="pfReview__goods--title" role="heading" aria-level="1">{goods.title}</p>
    </div>
  </section>
);

ReviewCreateGoodInfo.propTypes = {
  goods: PropTypes.shape({
    content_image_src: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    related_link_subject: PropTypes.string,
    related_link_names: PropTypes.array,
  }).isRequired,
};

export default ReviewCreateGoodInfo;
