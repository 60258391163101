import React from 'react';

import Layout from '../../layouts';
import PcPageBan from '../../components/review/ban/pc/ReviewBan';
import SpPageBan from '../../components/review/ban/sp/ReviewBan';
import { GetDeviceType } from '../../utils/page';
import { DEVICE_TYPE_SP } from '../../constants';

let PageBan = PcPageBan;
let subTitle = 'レビューの投稿';

// アクセス元デバイスがspの場合表示するコンポーネントとタイトルを変更
if (GetDeviceType() === DEVICE_TYPE_SP) {
  PageBan = SpPageBan;
  subTitle = 'Not Found';
}

// 404ページ
const Ban = () => (
  <Layout
    subTitle={subTitle}
  >
    <PageBan />
  </Layout>
);


export default Ban;
