import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';

import { RedirectToLoginPage } from '../utils/page';
import GetLogincheckReq from '../api/logincheck/logincheck';

const PrivateRouter = ({ component, history }) => {
  const [isLogin, setIsLogin] = useState(false);
  const [encodedCurrentURL, setEncodedCurrentURL] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const requestLoginCheckAPI = async () => {
      // ログインチェックAPIからログイン状況を取得
      const res = await GetLogincheckReq();

      // ログインチェックに失敗した場合はサーバエラーページにリダイレクト
      if (res.error) {
        history.push('/server-error');
      }

      setIsLogin(res.is_login);
      setEncodedCurrentURL(res.enc_current_url);
      setIsLoading(false);
    };

    // ログインチェック実行
    requestLoginCheckAPI();

    // NOTE_201910: 下記 ESLint 無効化について、history を依存関係に含める必要があるが、
    //              本処理（requestLoginCheckAPI()）には、本質的に関係ないので無視
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // APIのレスポンスを受け取るまでは何も表示しない
  if (isLoading) {
    return null;
  }

  // ログイン中であれば指定のページへ遷移
  if (isLogin) {
    const { component: Component } = { component };
    return (
      <Component />
      // FIXME: propsを渡したい場合↓
      // <Component {...props} />
    );
  }

  // 未ログインであればログインページにリダイレクト
  RedirectToLoginPage(encodedCurrentURL);

  return null;
};

export default withRouter(PrivateRouter);
